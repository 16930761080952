var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products"},[(!_vm.manufacture_name)?_c('Breadcrumb',{attrs:{"current_path":{name: 'Products', title: _vm.category_name, params: {..._vm.$route.params}}}}):_c('Breadcrumb',{attrs:{"current_path":{name: 'ProductsCategoryManufacture', title: _vm.manufacture_name, params: {..._vm.$route.params}},"previous_path":[
      {
          name: 'ProductsCategory',
          title: _vm.$route.params.category,
          params: {
              category: _vm.$route.params.category,
              manufacture: _vm.$route.params.manufacture
          }
      }
    ]}}),_c('div',{staticClass:"container products__container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-3"},[_c('LeftAside')],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-9"},[_c('div',{staticClass:"products__content"},[_c('h1',{staticClass:"title_3 products__title"},[_vm._v(_vm._s(_vm.$route.params.category)+" "+_vm._s(_vm.$t('pages.products.products')))]),(_vm.products.length)?_c('div',{staticClass:"products__list"},_vm._l((_vm.products),function(product){return _c('router-link',{key:'product-' + product.id,staticClass:"products__item",attrs:{"to":_vm.$i18nRoute({
                  name: 'Product',
                  params: {
                    category: product.category.name.toLowerCase(),
                    manufacture: product.manufacturer.name.toLowerCase().replace(' ', '-'),
                    name: product.name.toLowerCase(),
                    id: product.id,
                    query: _vm.$route.query
                  }
              })}},[_c('div',{staticClass:"products__item-image"},[_c('BaseImage',{attrs:{"path":product.img,"external":""}})],1),_c('div',{staticClass:"products__item-title"},[_vm._v(_vm._s(product.name))])])}),1):_vm._e(),(!_vm.products.length && !_vm.loader)?_c('p',{staticClass:"products__empty"},[_vm._v(_vm._s(_vm.$t('pages.products.empty-list')))]):_vm._e(),(_vm.loader)?_c('Loader',{staticClass:"news__loader"}):_vm._e()],1),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.products.length && _vm.page_count > 1),expression:"products.length && page_count > 1"}],attrs:{"count":_vm.page_count,"initial":_vm.initial_page},on:{"page":_vm.setOptionsPagination}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }