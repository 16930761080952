<template lang="pug">
  .products
    Breadcrumb(
      v-if="!manufacture_name"
      :current_path="{name: 'Products', title: category_name, params: {...$route.params}}"
    )

    Breadcrumb(
      v-else
      :current_path="{name: 'ProductsCategoryManufacture', title: manufacture_name, params: {...$route.params}}"
      :previous_path=`[
        {
            name: 'ProductsCategory',
            title: $route.params.category,
            params: {
                category: $route.params.category,
                manufacture: $route.params.manufacture
            }
        }
      ]`
    )


    .container.products__container
      .row
        .col-xs-12.col-sm-12.col-md-3
          LeftAside
        .col-xs-12.col-sm-12.col-md-9
          .products__content
            h1.title_3.products__title {{ $route.params.category }} {{ $t('pages.products.products') }}

            .products__list(v-if="products.length")
              router-link(
                v-for="product in products"
                :key="'product-' + product.id"
                :to=`$i18nRoute({
                    name: 'Product',
                    params: {
                      category: product.category.name.toLowerCase(),
                      manufacture: product.manufacturer.name.toLowerCase().replace(' ', '-'),
                      name: product.name.toLowerCase(),
                      id: product.id,
                      query: $route.query
                    }
                })`
              ).products__item
                .products__item-image
                  BaseImage(:path="product.img" external)
                .products__item-title {{ product.name }}
            p(v-if="!products.length && !loader").products__empty {{ $t('pages.products.empty-list') }}
            Loader(v-if="loader").news__loader
          Pagination(v-show="products.length && page_count > 1" :count="page_count" :initial="initial_page" @page="setOptionsPagination")
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import ExpandableItem from '@/components/common/ExpandableItem'
import LeftAside from '@/components/LeftAside'
import BaseImage from '@/components/common/BaseImage'
import Loader from '@/components/Loader'
import Pagination from '@/components/common/Pagination'
import { mapState } from 'vuex'
import ProductService from '@/services/product.service'

export default {
  name: 'Oral',
  components: { Loader, BaseImage, LeftAside, ExpandableItem, Breadcrumb, Pagination },
  props: {
    category_id: {
      type: Number,
      require: true
    },
    manufacturer_id: {
      type: Number,
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      category: null,
      category_name: '',
      manufacture: null,
      manufacture_name: '',
      loader: true,
      page_count: 1,
      initial_page: 1,
      options: {
        offset: 0,
        limit: 9
      }
    }
  },
  computed: {
    ...mapState({
      products: state => state.products.products,
      categories: state => state.categories.categories,
      categories_loaded: state => state.categories.categories_loaded
    })
  },
  watch: {
    '$route' (data) {
      this.category_name = this.$route.params.category
      this.manufacture_name = this.$route.params.manufacture

      if (!data.query.page) {
        this.setInitialPage()
      }
      this.checkQuery(data)
      this.getAllProducts()
    },
    categories_loaded () {
      if (this.categories_loaded) {
        this.checkQuery(this.$route)
        this.getAllProducts()
      }
    }
  },
  methods: {
    checkQuery (data) {
      if (data.query.page) {
        this.setInitialPage(data.query.page)
      }

      if (data.params.category) {
        this.setCategory(data.params.category)
      }

      if (data.params.manufacture) {
        this.setManufacture(data.params.manufacture)
      }
    },
    setInitialPage (page = 1) {
      this.initial_page = Number(page)
      this.setOptionsPagination(page)
    },
    setCategory (category) {
      this.category = this.category_id ? this.category_id : localStorage.getItem('category_' + category)
    },
    setManufacture (manufactureName) {
      this.categories.forEach(item => {
        if (Number(item.id) === Number(this.category)) {
          item.manufacturers.forEach(manufacture => {
            if (manufacture.name.toLowerCase().replace('-', ' ') === manufactureName.toLowerCase().replace('-', ' ')) {
              this.manufacture = manufacture.id
            }
          })
        }
      })
    },
    setOptionsPagination (page) {
      this.options.offset = this.options.limit * (page - 1)
      this.$router.replace({ query: { ...this.$route.query, page } }, () => {
      })
    },
    async getAllProducts () {
      this.loader = true
      this.$store.commit('products/setProducts', [])

      await ProductService.getProducts({
        category_id: this.category,
        manufacturer_id: this.manufacture,
        ...this.options
      })
          .then(response => {
            this.$store.commit('products/setProducts', response.products)
            this.page_count = Math.round(Math.ceil(response.count / this.options.limit))
            this.loader = false
          })
    }
  },
  mounted () {
    if (this.categories_loaded) {
      this.checkQuery(this.$route)
      this.getAllProducts()
    }
  },
  beforeMount () {
    this.category_name = this.$route.params.category
    this.manufacture_name = this.$route.params.manufacture
  },
  destroyed () {
    this.$store.commit('products/setProducts', [])
    this.initial_page = 1
  }
}
</script>
